import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import config from '../../../../gatsby-config';

// Components
import MainLayout from '../../../components/main-layout';
import HeaderSection from './header-section';
import FirstSection from './first-section';
import SecondSection from '../Free-Quick-Start-Package/second-section';
import ThirdSection from '../Free-Quick-Start-Package/third-section';
import ImInterestedForm from '../Free-Quick-Start-Package/im-interested-form';

import biuwerFreeQuickStartPackageImgEs from '../../../../static/img/biuwer-pack-inicio-gratuito.png';

const AceleraPyme = (props) => {
    const { pageContext } = props,
        { i18n, t } = useTranslation(),
        siteUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : config.siteMetadata.siteUrl,
        lang = pageContext.lang || i18n.language,
        img = biuwerFreeQuickStartPackageImgEs,
        imgUrl = `${siteUrl}${img}`;

    // Include custom image for this page, overwriting the default Helmet properties built from pageContext
    const customHelmetMetaTags = (
        <Helmet>
            <meta property="og:image" content={imgUrl} />
            <meta name="twitter:image" content={imgUrl} />
        </Helmet>
    );

    return (
        <MainLayout pageContext={pageContext}>
            {customHelmetMetaTags}
            <HeaderSection t={t} />
            <FirstSection t={t} />
            <SecondSection t={t} lang={lang} />
            <ThirdSection t={t} />
            <ImInterestedForm t={t} />
        </MainLayout>
    );
};

export default AceleraPyme;