/** @jsx jsx */
import { jsx } from '@emotion/react';

const FirstSection = (props) => {
    const { t } = props;

    return (
        <section style={{ backgroundColor: '#F9FBFD' }}>
            <div className="container mb50">
                <div className="row text-center pv40">
                    <div className="col-12">
                        <div className="pb20">
                            <h3>{t('lp:aceleraPyme.question')}</h3>
                        </div>
                        <div className="pb10">
                            <h5>{t('lp:aceleraPyme.biuwer_desc1')}</h5>
                        </div>
                        <div className="pb10">
                            <h5>{t('lp:aceleraPyme.biuwer_desc2')}</h5>
                        </div>
                        <div className="pb10">
                            <h5>{t('lp:aceleraPyme.biuwer_desc3')}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FirstSection;