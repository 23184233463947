/** @jsx jsx */
import { jsx } from '@emotion/react';

import config from '../../../../gatsby-config';
import aceleraPymeImg from '../../../../static/img/acelera-pyme.jpg';

const AceleraPymeHeaderSection =  (props) => {
    const { t } = props;

    return (
        <header
            id="headerSection"
            key="header"
            css={styles.mainSection}
        >
            <div className="container-fluid pt30">
                <div className="row text-center">
                    <div className="col-12">
                        <h1>{t('lp:aceleraPyme.title')}</h1>
                        <h3 className="mv30">{t('lp:aceleraPyme.subTitle')}</h3>
                        <div className="pb20 text-center">
                            <a href="https://acelerapyme.gob.es/" target="_blank" rel="noopener noreferrer"><img
                                src={aceleraPymeImg}
                                alt={t('lp:aceleraPyme.title')}
                            /></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        backgroundSize: 'cover',
        minHeight: 300,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    }
};

export default AceleraPymeHeaderSection;